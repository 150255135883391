import React from "react"

import Layout from "../components/layout"
import Snippet from "../components/snippet"

import "./code-of-conduct.css"

const CoCPage = () => (
	<Layout
		meta={{
			title: "Code of Conduct",
			description:
				"Our conference is dedicated to providing a harassment-free conference experience for everyone.",
			keywords: "code of conduct",
			path: "/code-of-conduct",
		}}
	>
		<Snippet id="coc-long" />
	</Layout>
)

export default CoCPage
